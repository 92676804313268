<script setup lang="ts">
const { cart } = useCart()
const { dispatchActionFinalizeOrderButtonEvent } = useGAActionEvents()
const checkoutStepNavigation = useCheckoutStepNavigation()

const selectedPaymentMethod: Ref<string | undefined> = useState(
  'selectedPaymentMethod',
  () => undefined
)

const sendGAFinalizeOrder = () => {
  if (
    cart.value?.shipments &&
    checkoutStepNavigation.currentStep.value?.id === 'CheckoutPayment'
  ) {
    dispatchActionFinalizeOrderButtonEvent(
      cart.value?.currency_code!,
      cart.value?.total_amount_float!,
      cart.value?.shipments[0].shipping_method?.reference!,
      cart.value?.payment_method?.name!
    )
  }
}

const runFunction = () => {
  if (selectedPaymentMethod.value === 'applepay') {
    const applePayButton = document.getElementById('applepay-checkout')
    if (applePayButton) {
      applePayButton.click()
      sendGAFinalizeOrder()
    }
    return
  }
  checkoutStepNavigation.handleContinueButtonClick(
    checkoutStepNavigation.currentStep.value.id
  )
  sendGAFinalizeOrder()
}
</script>

<template>
  <transition name="hide-bar">
    <div
      v-if="checkoutStepNavigation.showCta.value"
      class="checkout-step-navigation bg-primitives-white sticky bottom-0 left-0 flex w-full justify-center py-6"
    >
      <AtomsCta
        :disabled="!checkoutStepNavigation.isActive.value"
        @click-handler="runFunction"
      >
        <template #label>
          {{ checkoutStepNavigation.currentStep.value?.label }}
        </template>
      </AtomsCta>
    </div>
  </transition>
</template>

<style scoped lang="scss">
/* HIDE STRIP */
.hide-bar-enter-active,
.hide-bar-leave-active {
  transition: all 0.3s ease;
}

.hide-bar-enter-from,
.hide-bar-leave-to {
  @apply translate-y-full;
}
</style>
