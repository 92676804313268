<script setup lang="ts">
import { vFocusScroll } from '@design-system/directives/focusScroll'
const { isThankYouPage } = useCheckoutStepNavigation()

const { lineItems } = useCart()
</script>

<template>
  <div v-focus-scroll="'checkout-step-navigation'" class="theme-global">
    <CheckoutHeader />
    <GlobalSnackbars />
    <CartShippingSideBar />

    <main id="main" class="h-full w-full md:mb-0">
      <slot />
    </main>
    <CheckoutStepNavigation v-if="!isThankYouPage && lineItems.length > 0" />

    <CartFooter />

    <!-- SIDEBARS -->
    <CartContactUsSideSlide />
  </div>
</template>
