<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { getCountryName } = useCountryHelper()
const { country } = useRouteHelper()
const { openDialog: openContacts } = useDialog(
  SIDEBAR_IDS.cartContactUsSideSlide
)
const localePath = useLocalePath()
const { isThankYouPage } = useCheckoutStepNavigation()
const { dispatchActionContactCheckoutEvent } = useGAActionEvents()

const handleContactsClick = () => {
  dispatchActionContactCheckoutEvent('start', '')
  openContacts()
}
</script>
<template>
  <div
    class="border-b-primitives-grey-100 md:h-18 positive-padding flex h-[3.75rem] w-full items-center justify-between"
  >
    <NuxtLink
      v-if="isThankYouPage"
      :to="localePath('/')"
      :title="`${$ts('accessibility.goTo')} ${$ts('accessibility.homepage')}`"
    >
      <DefaultIconsGlobalMenuLogo
        class="h-5 cursor-pointer pr-7 md:h-6"
        aria-hidden="true"
      />
    </NuxtLink>
    <UtilsTooltip
      v-else
      class="flex"
      :aria-label="$ts('checkoutPage.switchPage.backToCartConfirm')"
    >
      <template #target>
        <DefaultIconsGlobalMenuLogo class="h-5 md:h-6" aria-hidden="true" />
      </template>
      <template #content>
        <p class="mb-4">
          {{ $ts('checkoutPage.switchPage.backToCartConfirmation') }}
        </p>
        <div class="flex justify-center gap-4">
          <MoleculesLinkWrapper
            size="s"
            anatomy="secondary"
            :aria-label="$ts('checkoutPage.switchPage.backToCartConfirm')"
            :to="localePath('/cart')"
          >
            <template #label>
              {{ $ts('checkoutPage.switchPage.backToCartConfirm') }}
            </template>
          </MoleculesLinkWrapper>
          <AtomsCta v-close-popper size="s">
            <template #label>
              {{ $ts('checkoutPage.switchPage.backToCartDeny') }}
            </template>
          </AtomsCta>
        </div>
      </template>
    </UtilsTooltip>
    <div class="gap-md flex w-fit items-center">
      <div class="text-book-7 line-clamp-2 text-ellipsis">
        <span>
          {{ $ts('cartPage.header.shippingTo') }}
          {{ getCountryName(country) }}
        </span>
      </div>
      <button
        :aria-label="$ts('contacts')"
        aria-haspopup="dialog"
        @click="handleContactsClick"
      >
        <DefaultIconsSupport class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>
